 import React from "react";
 import { NavLink } from "react-router-dom";
 import { SocialIcon } from "react-social-icons";

 const Footer = ({ language }) => {
   const content = {
     quickLinks: {
       en: [
         { name: "Who we Are", href: "/aboutUs" },
         { name: "Privacy Policy", href: "/privacy-policy" },
       ],
       ar: [
         { name: "من نحن", href: "/aboutUs" },
         { name: "سياسة الخصوصية", href: "/privacy-policy" },
       ],
     },
     contactDetails: {
       en: [
         {
           type: "email",
           title: "",
           value: (
             <>
               For any complaints or concerns, please reach out to us at{" "}
               <NavLink
                 to="mailto:support@sawaride.app"
                 className="text-gray-400 hover:text-white"
               >
                 support@sawaride.app
               </NavLink>
             </>
           ),
         },
         {
           type: "privacy policy",
           title: "",
           value: (
             <>
               For redressal of grievances, please refer to{" "}
               <NavLink
                 to="/privacy-policy"
                 className="text-gray-400 hover:text-white"
               >
                 Privacy Policy
               </NavLink>
             </>
           ),
         },
         {
           type: "address",
           title: "Registered Address",
           value: (
             <>
               Sawa Ride Information Technology Company Limited, Street #61, Al
               Nadwah Dist, Riyadh, Kingdom of Saudi Arabia, 14813 <br />
               <b>Commercial Registration Number</b> : 1009139621 <br />
               <b>VAT Registration Number</b> : 102240006118561
             </>
           ),
         },
       ],
       ar: [
         {
           type: "البريد الإلكتروني",
           title: "",
           value: (
             <>
               لأي شكاوى أو مخاوف، يرجى التواصل معنا على{" "}
               <NavLink
                 to="mailto:support@sawaride.app"
                 className="text-gray-400 hover:text-white"
               >
                 support@sawaride.app
               </NavLink>
             </>
           ),
         },
         {
           type: "سياسة الخصوصية",
           title: "",
           value: (
             <>
               للرد على الشكاوى، يرجى الرجوع إلى{" "}
               <NavLink
                 to="/privacy-policy"
                 className="text-gray-400 hover:text-white"
               >
                 <b>سياسة الخصوصية</b>
               </NavLink>
             </>
           ),
         },
         {
           type: "العنوان المسجل",
           title: "العنوان المسجل",
           value: (
             <>
               شركة سوا الركوب لتقنية المعلومات المحدودة، شارع رقم ٦١، حي
               الندوة، الرياض، المملكة العربية السعودية، ١٤٨١٣
               <br />
               <strong>رقم السجل التجاري:</strong> 1009139621
               <br />
               <strong>رقم تسجيل ضريبة القيمة المضافة:</strong> 102240006118561
             </>
           ),
         },
       ],
     },
     socialLinks: {
       en: [
         { platform: "Twitter", href: "https://www.x.com/sawa_ride" },
         { platform: "Facebook", href: "https://www.facebook.com" },
         { platform: "Instagram", href: "https://www.instagram.com/sawa.ride" },
         {
           platform: "LinkedIn",
           href: "https://www.linkedin.com/company/sawaride",
         },
       ],
       ar: [
         { platform: "تويتر", href: "https://www.x.com/sawa_ride" },
         { platform: "فيسبوك", href: "https://www.facebook.com" },
         { platform: "إنستغرام", href: "https://www.instagram.com/sawa.ride" },
         {
           platform: "لينكدإن",
           href: "https://www.linkedin.com/company/sawaride",
         },
       ],
     },
     footerText: {
       en: "Revolutionizing ride-hailing with zero commission and 100% driver earnings.",
       ar: "ثورة في خدمات النقل عبر التطبيقات بدون عمولة و100% أرباح للسائقين.",
     },
     builtText: {
       en: "Proudly Built in Saudi Arabia",
       ar: "بكل فخر تم بناؤه في المملكة العربية السعودية",
     },
     rightsText: {
       en: "© 2024 Sawa Ride Information Technology Company Limited. All rights reserved.",
       ar: "© شركة سوا الركوب لتقنية المعلومات المحدودة. جميع الحقوق محفوظة ۲۰۲٤",
     },
     sectionTitles: {
       quickLinks: {
         en: "About Us",
         ar: "معلومات عنا",
       },
       contactUs: {
         en: "Contact Us",
         ar: "اتصل بنا",
       },
       followUs: {
         en: "Follow Us",
         ar: "تابعونا",
       },
     },
   };

  const quickLinks = content.quickLinks[language] || content.quickLinks.en;
  const contactDetails =
    content.contactDetails[language] || content.contactDetails.en;
  const socialLinks = content.socialLinks[language] || content.socialLinks.en;
  const footerText = content.footerText[language] || content.footerText.en;
  const builtText = content.builtText[language] || content.builtText.en;
  const rightsText = content.rightsText[language] || content.rightsText.en;

  const sectionTitles = {
    quickLinks:
      content.sectionTitles.quickLinks[language] ||
      content.sectionTitles.quickLinks.en,
    contactUs:
      content.sectionTitles.contactUs[language] ||
      content.sectionTitles.contactUs.en,
    followUs:
      content.sectionTitles.followUs[language] ||
      content.sectionTitles.followUs.en,
  };
   return (
     <footer className="bg-black text-white py-12 relative overflow-hidden">
       <div className="container mx-auto px-4 relative z-10">
         <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
           <div className="space-y-4">
             <h3 className="text-xl font-bold mb-4">Sawa Ride</h3>
             <p className="text-gray-400 text-sm">{footerText}</p>
           </div>

           <div>
             <h4 className="text-lg font-semibold mb-4">
               {sectionTitles.quickLinks}
             </h4>
             <ul className="space-y-2">
               {quickLinks.map((link) => (
                 <li key={link.name}>
                   <NavLink
                     to={link.href}
                     className="text-gray-400 hover:text-white transition-colors duration-300"
                   >
                     {link.name}
                   </NavLink>
                 </li>
               ))}
             </ul>
           </div>

           <div>
             <h4 className="text-lg font-semibold mb-4">
               {sectionTitles.contactUs}
             </h4>
             <ul className="space-y-2">
               {contactDetails.map((detail) => (
                 <li key={detail.type} className="text-gray-400">
                   <b>{detail.title}</b>
                   {detail.type === "address" && <br />}
                   <span>{detail.value}</span>
                 </li>
               ))}
             </ul>
           </div>

           <div>
             <h4 className="text-lg font-semibold mb-4">
               {sectionTitles.followUs}
             </h4>
             <div className="flex space-x-4">
               {socialLinks.map((link) => (
                 <SocialIcon
                   bgColor="white"
                   fgColor="black"
                   key={link.platform}
                   url={link.href}
                   className="text-gray-400 hover:text-white transition-colors duration-300"
                   aria-label={link.platform}
                 />
               ))}
             </div>
           </div>
         </div>

         <div className="mt-8 pt-8 border-t border-gray-800 text-center">
           <div className="flex items-center justify-center space-x-2">
             <p className="text-gray-400 font-bold">{builtText}</p>
             <img
               src="https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"
               alt="Saudi flag"
               className="mx-auto"
               style={{ width: "20px", height: "auto" }}
             />{" "}
             
           </div>
           <p className="text-gray-400 text-sm">{rightsText}</p>
         </div>
       </div>
       <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-black opacity-50"></div>
       <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>
     </footer>
   );
 };

 export default Footer;
